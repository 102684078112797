#container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    min-height: 100%;
    width: 100%;
    height: 100%;
    background-color: #607d8b;
    color: #000000;
}

.canvas-container {
    border: 1px solid #0d3349;

}

.canvas-container canvas{
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.75);
}
