:root {
    --body-bg: #45484E;
    --sidebar-bg: #2B2C2F;
    --sidebar-footer-bg: #2A2A2D;
    --page-content-bg: #52555d;
    --icon-bg: #45484E;
    --icon-hover-bg: #45484E;
    --icon-color: #8A8E95;
    --icon-hover-color: #8A8E95;
    --icons-filter: none;
    --other-button-bg: #45484E;
    --other-button-hover-bg: #62656d;
    --text-color: #8A8E95;
    --text-hover-color: #DADEED;
    --border-color: #46484E;
    --border-light-color: #62656d;
    --border-dark-color: #383a40;
    --border-arrow-color: #8A8E95;
    --active-object-bg: #8A8E95;
    --toolbar-bg: #2B2C2F;
    --tools-icon-in-border: #46484E;
    --tools-icon-out-border: #1D1E20;
    --notificationSuccess: #21B978;
    --notificationInfo: #2B6CB0;
    --notificationWarning: #ECC94B;
    --notificationError: #C53030;
}

[data-theme="light"] {
    --body-bg: #E5E5E5;
    --sidebar-bg: #F0F2F6;
    --sidebar-footer-bg: #EAECF1;
    --page-content-bg: #E5E5E5;
    --icon-bg: #DADEE2;
    --icon-hover-bg: #DADEE2;
    --icon-color: #8A8E95;
    --icon-hover-color: #8A8E95;
    --icons-filter: none;
    --other-button-bg: #DADEE2;
    --other-button-hover-bg: #C7CBD0;
    --text-color: #8A8E95;
    --text-hover-color: #73767B;
    --border-color: #C7CBD0;
    --border-light-color: #DADEE2;
    --border-dark-color: #C7CBD0;
    --border-arrow-color: #8A8E95;
    --active-object-bg: #8A8E95;
    --toolbar-bg: #F4F6FA;
    --tools-icon-in-border: #DADEE2;
    --tools-icon-out-border: #8A8E95;
    --notificationSuccess: #21B978;
    --notificationInfo: #2B6CB0;
    --notificationWarning: #ECC94B;
    --notificationError: #C53030;
}

[data-theme="dark-blue"]{




    --body-bg: #35556D;
    --sidebar-bg: #1C3549;
    --sidebar-footer-bg: #12283A;
    --page-content-bg: #35556D;
    --icon-bg: #35556D;
    --icon-hover-bg: #35556D;
    --icon-color: #958b8a;
    --icon-hover-color: #8A8E95;
    --icons-filter: brightness(150%);
    --other-button-bg: #35556D;
    --other-button-hover-bg: #466B88;
    --text-color: #CFD5DF;
    --text-hover-color: #E8EDF5;
    --border-color: #35556D;
    --border-dark-color: #233d50;
    --border-light-color: #466B88;
    --border-arrow-color: #CFD5DF;
    --active-object-bg: #8A8E95;
    --toolbar-bg: #1C3549;
    --tools-icon-in-border: #35556D;
    --tools-icon-out-border: #12283A;
    --notificationSuccess: #21B978;
    --notificationInfo: #2B6CB0;
    --notificationWarning: #ECC94B;
    --notificationError: #C53030;
}

[data-theme="green"] {
    --body-bg: #355f5c;
    --sidebar-bg: #467572;
    --sidebar-footer-bg: #365f5c;
    --page-content-bg: #214845;
    --icon-bg: #BD8F26;
    --icon-hover-bg: #BD8F26;
    --icon-color: #8A8E95;
    --icon-hover-color: #8A8E95;
    --icons-filter: brightness(175%);
    --other-button-bg: #355f5c;
    --other-button-hover-bg: #BD8F26;
    --text-color: #d4f3f1;
    --text-hover-color: #d4f3f1;
    --border-color: #508884;
    --border-dark-color: #3e6f6c;
    --border-light-color: #BD8F26;
    --border-arrow-color: #F1F8FF;
    --active-object-bg: #BD8F26;
    --toolbar-bg: #467572;
    --tools-icon-in-border: #BD8F26;
    --tools-icon-out-border: #355f5c;
    --notificationSuccess: #21B978;
    --notificationInfo: #2B6CB0;
    --notificationWarning: #ECC94B;
    --notificationError: #C53030;
}

[data-theme="purple"] {
    --body-bg: #3d0e50;
    --sidebar-bg: #3d0e50;
    --sidebar-footer-bg: #3d0e50;
    --page-content-bg: #3d0e50;
    --icon-bg: #2662bd;
    --icon-hover-bg: #2662bd;
    --icon-color: #8A8E95;
    --icon-hover-color: #8A8E95;
    --icons-filter: brightness(175%);
    --other-button-bg: #3d0e50;
    --other-button-hover-bg: #2662bd;
    --text-color: #d4f3f1;
    --text-hover-color: #d4f3f1;
    --border-color: #3d0e50;
    --border-dark-color: #3d0e50;
    --border-light-color: #2662bd;
    --border-arrow-color: #F1F8FF;
    --active-object-bg: #2662bd;
    --toolbar-bg: #3d0e50;
    --tools-icon-in-border: #2662bd;
    --tools-icon-out-border: #3d0e50;
    --notificationSuccess: #21B978;
    --notificationInfo: #2B6CB0;
    --notificationWarning: #ECC94B;
    --notificationError: #C53030;
}